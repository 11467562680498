import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { fecharRemover } from "../actions";

function DialogExcluir({ open, etapa, onExcluir }) {
  const intl = useIntl();
  const [alias, setAlias] = useState("");
  const excluir = args => {
    onExcluir(args);
    setAlias("");
  };

  return (
    <Dialog open={open} onClose={() => excluir()}>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Deseja excluir a etapa?" })}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage(
            { defaultMessage: "Esta ação irá excluir a etapa &#34;{etapaNome}&#34;." },
            { etapaNome: etapa?.nome }
          )}
        </DialogContentText>
        <DialogContentText>
          {intl.formatMessage({
            defaultMessage:
              "É necessário inserir um identificador caso queira identificá-la futuramente nas métricas do Business Analytics.",
          })}
        </DialogContentText>
        <TextField
          label={intl.formatMessage({ defaultMessage: "Inserir identificador" })}
          fullWidth
          type="text"
          value={alias}
          onChange={e => setAlias(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => excluir({ excluiu: false })}>
          {intl.formatMessage({ defaultMessage: "Cancelar" })}
        </Button>
        <Button color="primary" onClick={() => excluir({ excluiu: true, alias })}>
          {intl.formatMessage({ defaultMessage: "Excluir" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogExcluir.propTypes = {
  open: PropTypes.bool,
  etapa: PropTypes.object,
  onExcluir: PropTypes.func.isRequired,
};

const mapState = ({ pipeline }) => ({ open: pipeline.dialogRemover.open, etapa: pipeline.dialogRemover.etapa });
const mapDispatch = { onExcluir: fecharRemover };

export default connect(
  mapState,
  mapDispatch
)(DialogExcluir);
