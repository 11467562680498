import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from "react-dnd";
import axios from "axios";
import { mutate } from "swr";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";

import { createSnackbarAPIException, createSnackbar, createAsyncDialog, AjaxBlackout } from "../../../../_common";
import { recarregarEtapa } from "../actions";
import permissions, { hasPermission } from "../../../../_common/permissions";
import { TIPO_GATE, TIPO_QUESTIONARIO } from "../../../../_common/constantes";
import { gerarLinkAgendamento } from "../../../lead/components/AgendarReuniao";
import { feedbackLink, filterLink } from "../../../lead/components/IniciarFiltro/IniciarFiltro";
import { useUsuario } from "../../../../hooks";
import { desfazerPularEtapas } from "../../../lead/components/DesfazerPularEtapas/useDesfazerPularEtapas";
import usePermissaoEtapa from "./usePermissaoEtapa";

const selectPularEtapas = state => state.pipeline.funil.pularEtapas;
export default function useEtapa(etapa) {
  const history = useHistory();
  const usuario = useUsuario();
  const podePularEtapas = useSelector(selectPularEtapas);
  const intl = useIntl();
  const permissaoNaEtapa = usePermissaoEtapa(etapa);
  const [leadEtapa, setLeadEtapa] = useState(null);
  const [escolherQuestionario, setEscolherQuestionario] = useState(false);
  const dispatch = useDispatch();
  const [vender, setVender] = useState(false);
  const [cancelarReuniao, setCancelarReuniao] = useState(false);
  const [desfazerVenda, setDesfazerVenda] = useState(false);
  const [mostrarRealizarQuestionario, setMostrarRealizarQuestionario] = useState(false);
  const [mostrarRealizarAgendamento, setMostrarRealizarAgendamento] = useState(false);

  async function pularEtapas(etapaOrigem, etapaDestino, lead) {
    try {
      await createAsyncDialog({
        title: intl.formatMessage({ defaultMessage: "Pular etapas" }),
        text: intl.formatMessage(
          {
            defaultMessage:
              "O lead vai pular da etapa {etapaOrigem} para a etapa {etapaDestino}, ignorando todas as transições e métricas entre elas.",
          },
          {
            etapaOrigem: etapaOrigem.nome,
            etapaDestino: etapaDestino.nome,
          }
        ),
        acceptLabel: intl.formatMessage({ defaultMessage: "Pular etapas" }),
        tamanhoMaximo: "sm",
      });
      AjaxBlackout.Show();
      const [etapaOrigemId, etapaDestinoId] = [etapaOrigem.id, etapaDestino.id];
      const { data: success } = await axios.post("/api/pipeline/lead/pularEtapas", {
        id: lead.id,
        etapaOrigemId,
        etapaDestinoId,
      });
      callback(success, etapaOrigemId, etapaDestinoId);
    } catch (err) {
      if (err) createSnackbarAPIException(err);
      // user declined, do nothing
    } finally {
      AjaxBlackout.Hide();
    }
  }

  async function moveLead(origem, destino, lead) {
    AjaxBlackout.Show();
    try {
      const { data: moved } = await axios.post("/api/pipeline/lead/movermanualmente", {
        id: lead,
        etapaOrigemId: origem,
        etapaDestinoId: destino,
      });
      callback(moved, origem, destino);
      createSnackbar(intl.formatMessage({ defaultMessage: "Lead movido com sucesso" }));
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  const callbackRealizarQuestionario = async (accepted, item) => {
    if (accepted) aplicarQuestionario(item);
    else {
      try {
        AjaxBlackout.Show();
        const [etapaOrigemId, etapaDestinoId] = [item.etapa.id, etapa.id];
        const { data: success } = await axios.post("/api/pipeline/lead/pularEtapas", {
          id: item.lead.id,
          etapaOrigemId,
          etapaDestinoId,
        });
        callback(success, etapaOrigemId, etapaDestinoId);
      } catch (err) {
        if (err) createSnackbarAPIException(err);
      } finally {
        AjaxBlackout.Hide();
        setMostrarRealizarQuestionario(false);
      }
    }
  };

  const callbackRealizarAgendamento = async (accepted, item) => {
    if (accepted) {
      AjaxBlackout.Show();
      history.push({
        pathname: "/spotter/reuniao",
        search: `?leadId=${item.lead.id}`,
        state: { etapaAgendamentoId: etapa.id },
      });
    } else {
      try {
        AjaxBlackout.Show();
        const [etapaOrigemId, etapaDestinoId] = [item.etapa.id, etapa.id];
        const { data: success } = await axios.post("/api/pipeline/lead/pularEtapas", {
          id: item.lead.id,
          etapaOrigemId,
          etapaDestinoId,
        });
        callback(success, etapaOrigemId, etapaDestinoId);
      } catch (err) {
        if (err) createSnackbarAPIException(err);
      } finally {
        AjaxBlackout.Hide();
        setMostrarRealizarAgendamento(false);
      }
    }
  };

  async function aplicarQuestionario(item) {
    AjaxBlackout.Show();
    const url = `/api/pipeline/lead/BuscarQuestionariosPossiveis?id=${item.lead.id}`;
    const { data: questionarios } = await axios.get(url);
    mutate(url, questionarios, false);
    AjaxBlackout.Hide();
    if (questionarios.length > 1) return setEscolherQuestionario(true);
    if (questionarios.length) return navigateToFilter(questionarios[0], item);
    return navigateToFilter(item.etapa.questionario.id, item);
  }

  const navigateToFilter = (questionario, item = leadEtapa) => {
    if (!questionario) return null;
    const reuniaoId = item.lead?.reuniao?.id ?? item.lead.agendamento?.id;
    const isReuniao = reuniaoId && questionario.tipoQuestionario === TIPO_QUESTIONARIO.FEEDBACK;

    const link = isReuniao
      ? feedbackLink(reuniaoId)
      : filterLink(item.lead.id, item.etapa.id, questionario.questionario.id);
    return history.push(link);
  };

  const callback = (moved, origem, destino) => {
    if (moved) {
      dispatch(recarregarEtapa(origem ?? leadEtapa.etapa.id));
      dispatch(recarregarEtapa(destino ?? etapa.id));
    }
    setVender(false);
    setDesfazerVenda(false);
    setCancelarReuniao(false);
    setEscolherQuestionario(false);
  };

  const [, dropRef] = useDrop({
    accept: "LEAD",
    drop: async item => {
      setLeadEtapa(item);

      if (item.lead.conversao.etapaDesfazerPuloId === etapa.id)
        return desfazerPularEtapas(etapa, item.etapa, item.lead, callback);

      // abrir modal venda se destino é venda e lead não foi vendido
      if (!item.etapa.posVenda && etapa.gateAnterior === TIPO_GATE.VENDA) return setVender(true);
      // abrir modal desfazer venda se destino é original de um lead vendido
      if (item.etapa.posVenda && item.lead.venda?.etapaId === etapa.id) return setDesfazerVenda(true);

      // se origem aponta destino, ou vice-versa, e o gate entre elas for manual, transfere
      if (
        (item.etapa.proximaEtapaId === etapa.id && item.etapa.tipoGate === TIPO_GATE.MANUAL) ||
        (etapa.proximaEtapaId === item.etapa.id && etapa.tipoGate === TIPO_GATE.MANUAL)
      ) {
        return moveLead(item.etapa.id, etapa.id, item.lead.id);
      }

      // se pode pular, não cruza gate vendas e etapas não forem vizinhas, pede pra pular
      if (
        podePularEtapas &&
        item.etapa.ordem < etapa.ordem &&
        item.etapa.posVenda === etapa.posVenda &&
        item.etapa.proximaEtapaId !== etapa.id
      ) {
        if (etapa.gateAnterior === TIPO_GATE.AGENDAMENTO) setMostrarRealizarAgendamento(true);
        else pularEtapas(item.etapa, etapa, item.lead);

        return true;
      }

      // agendar/reagendar reunião se movendo pra frente e conversão é agendamento
      const temReuniao = !!item.lead.agendamento?.id || !!item.lead.reuniao?.id;
      if (item.etapa.proximaEtapaId === etapa.id && item.etapa.tipoGate === TIPO_GATE.AGENDAMENTO) {
        const linkReuniao = gerarLinkAgendamento(
          { ...item.lead, etapa: item.etapa },
          usuario.isGerente,
          temReuniao,
          permissaoNaEtapa
        );
        AjaxBlackout.Show();
        window.location.href = linkReuniao;
        return undefined;
      }

      // cancelar reunião se movendo pra trás e conversão é agendamento
      if (
        etapa.proximaEtapaId === item.etapa.id &&
        etapa.tipoGate === TIPO_GATE.AGENDAMENTO &&
        temReuniao &&
        item.lead.agendamento.etapaAgendamentoId === item.lead.etapa.id
      ) {
        return setCancelarReuniao(true);
      }

      // se lead tem reunião, é feedback, se não, é filtro
      if (etapa.gateAnterior === TIPO_GATE.FILTRO) setMostrarRealizarQuestionario(true);

      // nenhum caso, faça nada
      return null;
    },
    canDrop: item => {
      // validar se o usuário pode usar dragn'drop foi feito no item

      // se mesma etapa, não pode
      if (item.etapa.id === etapa.id) return false;

      // helper local pra validar permissão do usuário no lead
      const checkPermission = claim => hasPermission(usuario, [claim], [item.lead]);

      // se atravessando gate de vendas, só vender ou desfazer venda
      if (etapa.posVenda !== item.etapa.posVenda) {
        // se lead não foi vendido e destino for imediatamente após gate de venda
        if (!item.lead.venda?.etapaId && etapa.gateAnterior === TIPO_GATE.VENDA)
          return checkPermission(permissions.MARCAR_VENDIDO);
        // se lead é vendido e destino é etapa origem antes da venda
        if (item.lead.venda?.etapaId === etapa.id) return checkPermission(permissions.MARCAR_VENDIDO);
        // se não, não pode
        return false;
      }

      // se último movimento do lead foi pulo, só pode desfazer o pulo ou pular pra frente
      if (item.lead.conversao.etapaDesfazerPuloId && item.lead.tipo === 7) {
        // se destino for igual à origem do pulo
        if (item.lead.conversao.etapaDesfazerPuloId === etapa.id)
          return podePularEtapas && checkPermission(permissions.PULAR_ETAPAS);
        // se está pulando pra trás
        if (etapa.ordem < item.etapa.ordem) return false;
      }

      // se pulando mais de uma etapa, não pode se não tiver permissão e não pode pra trás
      if (Math.abs(etapa.ordem - item.etapa.ordem) > 1) {
        // não pode voltar mais de 1
        if (etapa.ordem < item.etapa.ordem) return false;
        // se não tem permissão de pulo, não pode pular mais de 1 etapa
        return podePularEtapas && checkPermission(permissions.PULAR_ETAPAS);
      }

      // pode agendar se destino for próxima da origem
      const temReuniao = !!item.lead.agendamento?.id || !!item.lead.reuniao?.id;
      const podeAgendar = !!gerarLinkAgendamento(
        { ...item.lead, etapa: item.etapa },
        usuario.isGerente,
        temReuniao,
        permissaoNaEtapa
      );

      if (item.etapa.proximaEtapaId === etapa.id && item.etapa.tipoGate === TIPO_GATE.AGENDAMENTO) return podeAgendar;

      // pode cancelar agendamento se origem for próxima de destino
      if (
        etapa.proximaEtapaId === item.etapa.id &&
        etapa.tipoGate === TIPO_GATE.AGENDAMENTO &&
        temReuniao &&
        item.lead.agendamento.etapaAgendamentoId === item.lead.etapa.id
      )
        return true;

      // pode mover manualmente pra trás se origem for próxima de destino
      if (etapa.proximaEtapaId === item.etapa.id && etapa.tipoGate === TIPO_GATE.MANUAL) return true;

      // se não for um caso acima, só pode mover pra frente se destino for próxima de origem
      if (item.etapa.proximaEtapaId !== etapa.id) return false;

      // tirando os casos acima, só verificar claim da gate anterior ao destino
      switch (etapa.gateAnterior) {
        case TIPO_GATE.FILTRO:
          return checkPermission(temReuniao ? permissions.APLICAR_FEEDBACK : permissions.APLICAR_FILTRO);
        case TIPO_GATE.AGENDAMENTO:
          return !temReuniao || checkPermission(permissions.REAGENDAR);
        case TIPO_GATE.MANUAL:
          return true;
        case TIPO_GATE.VENDA:
          return checkPermission(permissions.MARCAR_VENDIDO);
        default:
          return false;
      }
    },
  });

  return useMemo(
    () => ({
      dropRef,
      permissaoNaEtapa,
      leadEtapa,
      escolherQuestionario,
      vender,
      desfazerVenda,
      cancelarReuniao,
      mostrarRealizarQuestionario,
      mostrarRealizarAgendamento,
      navigateToFilter,
      callback,
      setMostrarRealizarQuestionario,
      setMostrarRealizarAgendamento,
      callbackRealizarQuestionario,
      callbackRealizarAgendamento,
    }),
    [
      dropRef,
      callback,
      permissaoNaEtapa,
      leadEtapa,
      escolherQuestionario,
      vender,
      desfazerVenda,
      navigateToFilter,
      cancelarReuniao,
      setMostrarRealizarQuestionario,
      setMostrarRealizarAgendamento,
      callbackRealizarQuestionario,
      callbackRealizarAgendamento,
    ]
  );
}
