import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { useIntl } from "react-intl";
import { TIPO_APLICACAO_QUESTIONARIO } from "../../../../_common/constantes";

SelecaoAutomatica.propTypes = { children: PropTypes.node, disabled: PropTypes.bool };
export default function SelecaoAutomatica({ children, disabled, ...props }) {
  const intl = useIntl();
  const [field, , { setValue }] = useField("tipoAplicacaoQuestionario", props);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={field.value === TIPO_APLICACAO_QUESTIONARIO.DINAMICO}
            onChange={(e, checked) =>
              setValue(checked ? TIPO_APLICACAO_QUESTIONARIO.DINAMICO : TIPO_APLICACAO_QUESTIONARIO.PADRAO)
            }
            disabled={disabled}
          />
        }
        label={intl.formatMessage({ defaultMessage: "Seleção automática de questionário" })}
      />

      <Tooltip
        title={intl.formatMessage({
          defaultMessage:
            "Essa opção habilita o uso de regras de segmentação, através das configurações, para seleção do questionário a ser aplicado ao iniciar o filtro de etapas com saída do tipo Filtrar",
        })}
      >
        <HelpIcon fontSize="small" />
      </Tooltip>

      {children}
    </>
  );

  // return (
  //   <>
  //     <Field
  //       component={CheckboxWithLabel}
  //       type="checkbox"
  //       name="tipoAplicacaoQuestionario"
  //       Label={{
  //         label: intl.formatMessage({ defaultMessage: "Seleção automática de questionário" }),
  //       }}
  //       {...props}
  //     />
  //     <Tooltip
  //       title={intl.formatMessage({
  //         defaultMessage:
  //           "Essa opção habilita o uso de regras de segmentação, através das configurações, para seleção do questionário a ser aplicado ao iniciar o filtro de etapas com saída do tipo Filtrar",
  //       })}
  //     >
  //       <HelpIcon fontSize="small" />
  //     </Tooltip>

  //     {children}
  //   </>
  // );
}
