import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FieldArray, useFormikContext } from "formik";
import { IconButton, Grid, Collapse, withStyles } from "@material-ui/core";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useIntl } from "react-intl";
import useSWR from "swr";

import SelectQuestionario from "../../../../components/SelectQuestionario";
import SelectRegra from "../../../../components/SelectRegra";
import { TIPO_QUESTIONARIO } from "../../../../_common/constantes";

EditarSelecaoAutomatica.propTypes = {
  etapaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
function EditarSelecaoAutomatica({ etapaId, name, disabled, classes }) {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(e => !e);
  const { values, setFieldValue, submitCount } = useFormikContext();

  const { data: regras } = useSWR(
    () => (etapaId ? `/api/pipeline/SegmentacaoRegraEtapaQuestionario/Buscar?etapaId=${etapaId}` : null)
  );

  useEffect(
    () => {
      if (submitCount) setExpanded(true);
    },
    [submitCount]
  );

  useEffect(
    () => {
      setExpanded(!disabled);
    },
    [disabled]
  );

  useEffect(
    () => {
      if (regras?.length) setFieldValue(name, regras);
    },
    [regras]
  );

  return (
    <FieldArray name={name}>
      {({ remove, push }) => (
        <>
          <IconButton id={`button-expandir`} disabled={!values[name].length || disabled} onClick={toggleExpanded}>
            {expanded && values[name].length ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>

          <IconButton
            disabled={disabled}
            onClick={() => {
              if (!expanded) toggleExpanded();
              push({ questionarioId: "", regraId: "" });
            }}
          >
            <AddIcon />
          </IconButton>

          <Collapse className={classes.collapse} in={expanded}>
            {values[name]?.map((value, index) => (
              <Grid container spacing={8} key={index} alignItems="center">
                <Grid item xs={5}>
                  <SelectQuestionario
                    id={`select-regra-questionario-${index}`}
                    tipos={[TIPO_QUESTIONARIO.QUESTIONARIO_LEAD]}
                    label={intl.formatMessage({ defaultMessage: "Questionário" })}
                    name={`${name}.${index}.questionarioId`}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={5}>
                  <SelectRegra name={`${name}.${index}.regraId`} fullWidth />
                </Grid>

                <Grid item xs={2}>
                  {values[name].length > 1 && (
                    <IconButton
                      onClick={() => {
                        remove(index);
                        if (!values[name].length === 1) setExpanded(false);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Collapse>
        </>
      )}
    </FieldArray>
  );
}

export default withStyles({
  collapse: { minHeight: 70 },
})(EditarSelecaoAutomatica);
