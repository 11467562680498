import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useIntl } from "react-intl";

import { fecharMover } from "../actions";

function DialogMover({ open, origem, anterior, posterior, lead, onMover }) {
  const intl = useIntl();
  const [etapa, setEtapa] = useState("");
  const mover = moveu => {
    setEtapa("");
    if (moveu) onMover({ lead, destino: anterior && anterior.id.toString() === etapa ? anterior : posterior, origem });
    else onMover({});
  };

  return (
    <Dialog open={open} onClose={() => mover()} id="dialogEtapaDestino">
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Mover lead" })}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage({ defaultMessage: "Selecione a etapa destino do lead" })}
        </DialogContentText>
        {(anterior || posterior) && (
          <FormControl component="fieldset">
            <RadioGroup name="etapa" value={etapa} onChange={e => setEtapa(e.target.value)}>
              {anterior && (
                <FormControlLabel
                  value={anterior.id.toString()}
                  label={anterior.nome}
                  control={<Radio color="primary" />}
                />
              )}
              {posterior && (
                <FormControlLabel
                  value={posterior.id.toString()}
                  label={posterior.nome}
                  control={<Radio color="primary" />}
                />
              )}
            </RadioGroup>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => mover(false)}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
        <Button color="primary" onClick={() => mover(true)} disabled={!etapa}>
          {intl.formatMessage({ defaultMessage: "Mover" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogMover.propTypes = {
  open: PropTypes.bool,
  origem: PropTypes.object,
  anterior: PropTypes.object,
  posterior: PropTypes.object,
  lead: PropTypes.object,
  onMover: PropTypes.func,
};

const mapState = ({ pipeline }) => ({
  open: pipeline.dialogMover.open,
  origem: pipeline.dialogMover.origem,
  anterior: pipeline.dialogMover.anterior,
  posterior: pipeline.dialogMover.posterior,
  lead: pipeline.dialogMover.lead,
});
const mapDispatch = { onMover: fecharMover };

export default connect(
  mapState,
  mapDispatch
)(DialogMover);
