import axios from "axios";

import { objectKeysToCamelCase, toUpperCamel } from "../../../_common/utils/string";
import { AjaxBlackout } from "../../../_common";

const interceptor = ({ data }) => objectKeysToCamelCase(data);
const API_ROOT = "/api/pipeline";

const sources = {};
// TODO rename method
function startNewFetch(id) {
  const source = sources[id];
  if (source) source.cancel();
  sources[id] = axios.CancelToken.source();
  return sources[id].token;
}

const atualizarFiltros = filtros => {
  let retorno = filtros;
  const filtroSalvo = JSON.parse(localStorage.getItem(`BuscaAvancadaSalva`));
  if (filtroSalvo !== null) retorno = { ...retorno, ...filtroSalvo.parametros };

  return retorno;
};

export function getLeads(etapaId, idUsuario, args) {
  if (idUsuario) {
    args.filtros = atualizarFiltros(args.filtros, idUsuario);
  }

  args.filtros.camposPersonalizados = args.filtros?.condicoesInternas
    ?.filter(condicao => condicao?.campoPersonalizado?.valor)
    .map(condicao => condicao.campoPersonalizado);

  const cancelToken = startNewFetch(etapaId);
  return axios.post(`${API_ROOT}/lead/pesquisar`, { etapaId, ...args }, { cancelToken }).then(interceptor);
}

export function getEtapas(funilId) {
  return axios.get(`${API_ROOT}/funil/buscar?id=${funilId}`).then(interceptor);
}

export function ordenar(id, campo, sentido) {
  return axios.post(`${API_ROOT}/configuracaousuarioetapa/salvar`, toUpperCamel({ etapaid: id, campo, sentido }));
}

export function moverEtapa({ id, antiga, nova, funilId, nome, questionarioId }) {
  AjaxBlackout.Show();
  return axios
    .post(`${API_ROOT}/etapa/mover`, {
      Id: id,
      AntigaProximaEtapaId: antiga,
      ProximaEtapaId: nova,
      Alias: "",
      FunilId: funilId,
      nome,
      questionarioId,
    })
    .then(interceptor)
    .finally(() => AjaxBlackout.Hide());
}

export function remover({ id, proximaEtapaId, funilId, alias, nome, questionarioId }) {
  AjaxBlackout.Show();
  return axios
    .post(`${API_ROOT}/etapa/remover`, toUpperCamel({ id, proximaEtapaId, funilId, alias, nome, questionarioId }))
    .finally(() => AjaxBlackout.Hide());
}

export function salvar({ id, ...etapa }) {
  const { gate, gateAnterior, hasMore, isLoading, leads, ordenacao, pagina, totalLeads, ...args } = etapa;
  AjaxBlackout.Show();
  return axios.post(`${API_ROOT}/etapa/salvar`, toUpperCamel({ id, ...args })).finally(() => AjaxBlackout.Hide());
}

export function getFiltros() {
  return axios.get(`${API_ROOT}/Questionario/listar`, { params: { tipos: [1, 2] } }).then(interceptor);
}

export function moverLead(lead, etapa) {
  AjaxBlackout.Show();
  return axios
    .post(`${API_ROOT}/lead/movermanualmente`, { Id: lead.id, etapaOrigemId: lead.etapa.id, etapaDestinoId: etapa.id })
    .finally(() => AjaxBlackout.Hide());
}
