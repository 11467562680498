import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { InputBase } from "@material-ui/core";

const Campo = ({ texto, onSave, ...props }) => {
  const [valor, set] = useState("");
  const key = useRef("");
  const ref = useRef(null);
  const ESCAPE = "Escape";

  useEffect(
    () => {
      set(texto);
    },
    [texto]
  );

  const handleBlur = () => {
    if (valor && key.current !== ESCAPE) {
      onSave(valor.trim());
      set(valor.trim());
    } else {
      set(texto);
    }
  };

  return (
    <InputBase
      {...props}
      inputRef={ref}
      value={valor}
      onChange={e => set(e.currentTarget.value)}
      inputProps={{ style: { padding: 0 } }}
      onBlur={handleBlur}
      onKeyDown={e => {
        key.current = e.key;
        if (e.key === ESCAPE || e.key === "Enter") ref.current.blur();
      }}
    />
  );
};

Campo.propTypes = {
  texto: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default Campo;
