import React, { useEffect, useState, useRef } from "react";
import useSWR from "swr";
import axios from "axios";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { ArrowDropDown } from "@material-ui/icons";
import { Button, Menu, MenuItem, ListItem, ListItemIcon, ListItemText, Typography, Divider } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import { Loading, Flex } from "../../../_common";
import { withUsuario, injectSheet } from "../../../_common/hocs";
import Filtros from "../funil/Filtros/Filtros";
import Flyout from "../funil/Flyout";
import { Etapa, ModalEtapa, DialogExcluir, DialogMover } from "./components";
import { abrirEditar, buscarEtapas as buscarFasesLead, renomearFunil } from "./actions";
import EtapaOculta from "./components/EtapaOculta";
import { useEvent, useSelectPermission, useTipoPlanoSpotter, useUsuario } from "../../../hooks";
import { Subheader } from "../../../components";
import permissions from "../../../_common/permissions";
import { ACCESS_LEVEL } from "../../../_common/constantes";

const TAMANHO_NAVBAR = 57;
const TAMANHO_SUBHEADER = 72;
const TAMANHO_BANNER_RD = 68;

function Pipeline({ etapas, buscarEtapas, filtros, classes }) {
  const intl = useIntl();
  const [funilSalvo, setFunilSalvo] = useState("");
  const [renderFunil, setRenderFunil] = useState(`${TAMANHO_NAVBAR + TAMANHO_SUBHEADER}px`);
  const [open, setOpen] = useState(false);
  const menuAnchorRef = useRef();
  const history = useHistory();
  const permissaoFunil = useSelectPermission(permissions.VISUALIZAR_BASE);
  const { isGerente } = useUsuario();
  const isSpotterFull = useTipoPlanoSpotter();

  const buscarFunilSelecionado = async () => {
    const { data: funilCarregado } = await axios.get("/Api/Pipeline/ConfiguracaoUsuarioFunil/Selecionado");
    const funilId = funilCarregado !== 0 ? funilCarregado : null;
    buscarEtapas(funilId);
    mutate();
  };

  const montarRenderFunil = () => {
    const bannerAberto = document.getElementsByClassName("rd-summit-navbar show");
    if (!localStorage.getItem(`dimmissRDSummitStickbar`) && bannerAberto.length > 0)
      setRenderFunil(`${TAMANHO_NAVBAR + TAMANHO_SUBHEADER + TAMANHO_BANNER_RD}px`);
    else setRenderFunil(`${TAMANHO_NAVBAR + TAMANHO_SUBHEADER}px`);
  };

  window.addEventListener("changeRenderFunil", montarRenderFunil);

  useEffect(() => {
    if (permissaoFunil && permissaoFunil.nivelAcesso === ACCESS_LEVEL.NONE)
      history.push(`/spotter/acesso-restrito/sem-permissao-acesso/`);

    buscarFunilSelecionado();
    document.title = `${intl.formatMessage({ defaultMessage: "Base de Leads" })} - Exact Sales`;

    montarRenderFunil();
  }, []);

  useEvent("pipeline/reload", () => {
    buscarFunilSelecionado();
  });

  const { data: funis, mutate } = useSWR("/api/pipeline/funil/listar");

  async function selectFunil(funil) {
    setFunilSalvo(funil);
    setOpen(false);
    buscarEtapas(funil.id);
    await axios.post(`/Api/Pipeline/ConfiguracaoUsuarioFunil/Selecionado?funilId=${funil.id}`);
    mutate();
  }

  let funilPadrao;
  if (funis?.some(funil => funil.selecionado === true))
    funilPadrao = funis ? funis?.find(funil => funil.selecionado === true) : [];
  else funilPadrao = funis ? funis?.find(funil => funil.padrao === true) : [];

  const title = funilSalvo ? funilSalvo?.nome : funilPadrao?.nome;
  const getBreadcrumb = () => [{ titulo: intl.formatMessage({ defaultMessage: "Funil" }) }, { titulo: title }];

  const abrirSelect = () => {
    setOpen(true);
    mutate();
  };

  return (
    <Flex flexDirection="column" style={{ overflow: "hidden !important", height: `calc(100vh - ${renderFunil})` }}>
      <Flex flexDirection="row" className={classes.subheader}>
        <Subheader hideBackButton title={title} breadcrumb={getBreadcrumb()} />
        {isSpotterFull && (
          <Button onClick={abrirSelect} buttonRef={menuAnchorRef} className={classes.buttonSubheader}>
            <ArrowDropDown />
          </Button>
        )}
      </Flex>
      {isSpotterFull && (
        <Menu
          keepMounted
          open={open}
          anchorEl={menuAnchorRef.current}
          onClose={() => setOpen(false)}
          className={classes.dropdown}
        >
          {funis?.map(funil => (
            <MenuItem key={funil.id} onClick={() => selectFunil(funil)} selected={funil.id === funilSalvo.id}>
              <Flex justifyContent="space-between" alignItems="center" style={{ minWidth: 250 }}>
                {funil.nome}
              </Flex>
            </MenuItem>
          ))}
          {isGerente ? (
            <div>
              <Divider />
              <MenuItem style={{ display: "flex", flexDirection: "row" }}>
                <ListItem
                  button
                  component={Link}
                  to="/spotter/base-leads/funil/listar"
                  target="_blank"
                  onClick={() => setOpen(false)}
                  style={{ display: "flex", flexDirection: "row", paddingLeft: "0px", backgroundColor: "#f4f5f700" }}
                >
                  <ListItemIcon className={classes.botaoCriarNovo}>
                    <AddCircle style={{ color: "#457AB7" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography className={classes.labelCriarNovo}>
                        {intl.formatMessage({ defaultMessage: "Criar novo" })}
                      </Typography>
                    }
                    className={classes.labelCriarNovo}
                  />
                </ListItem>
              </MenuItem>
            </div>
          ) : null}
        </Menu>
      )}

      <Filtros funilId={funilSalvo ? funilSalvo?.id : funilPadrao?.id} />

      <DndProvider backend={HTML5Backend}>
        {etapas.length ? (
          <Flex id="etapas-container" className={classes.etapas} style={{ marginLeft: 8, height: "100%" }}>
            {etapas.map(etapa => {
              if (etapa.oculta) {
                return <EtapaOculta key={etapa.id} etapa={etapa} />;
              }
              return (
                <Etapa
                  key={etapa.id}
                  etapa={etapa}
                  filtros={filtros}
                  funilId={funilSalvo ? funilSalvo?.id : funilPadrao?.id}
                />
              );
            })}
          </Flex>
        ) : (
          <Loading isLoading />
        )}
      </DndProvider>

      <Flyout />
      <ModalEtapa />
      <DialogExcluir />
      <DialogMover />
    </Flex>
  );
}

Pipeline.propTypes = {
  etapas: PropTypes.array,
  buscarEtapas: PropTypes.func.isRequired,
  filtros: PropTypes.object,
  classes: PropTypes.object,
};

const enhance = compose(
  injectSheet({
    titulo: {
      padding: "5px 5px",
      height: "24px",
      display: "flex",
      marginLeft: 8,
      marginRight: 2,
      marginTop: 2,
      alignItems: "center",
      fontSize: "26px",
      fontWeight: 300,
      borderRadius: 5,
      border: "1px solid transparent",
      "&:hover": { borderColor: "lightgray" },
      "&:focus-within": { boxShadow: "0 0 0 2px #457AB7" },
    },
    settingsIcon: {
      fontSize: 20,
      color: "gray",
      cursor: "pointer",
      "&:hover": { color: "black" },
    },
    etapas: {
      flexGrow: 1,
      height: "100%",
      overflow: "scroll hidden",
      marginBottom: -72,
    },
    subheader: {
      display: "flex",
      alignItems: "flex-center",
      marginBottom: -10,
      backgroundColor: "#F5F5F5 !important",
    },
    buttonSubheader: {
      marginLeft: -15,
      padding: "35px 40px 0px 0px",
    },
    botaoCriarNovo: {
      marginRight: "0px !important",
    },
    labelCriarNovo: {
      color: "#457AB7 !important",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    dropdown: {
      position: "absolute",
      top: 50,
    },
  }),
  connect(
    ({ pipeline, baseLeads }) => ({
      etapas: pipeline.etapas,
      funil: pipeline.funil,
      filtros: baseLeads.modals.buscaAvancada.filtros,
    }),
    {
      buscarEtapas: buscarFasesLead,
      renomear: renomearFunil,
      editarEtapa: abrirEditar,
    }
  ),
  withUsuario
);

export default enhance(Pipeline);
