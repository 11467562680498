import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Avatar, Tooltip, Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

import { Tipografia, Flex, Divider } from "../../../../_common";
import injectSheet from "../../../../_common/hocs/injectSheet/injectSheet";

function Etapa({ etapa, classes }) {
  const intl = useIntl();
  return (
    <React.Fragment>
      <Flex style={{ width: 240, cursor: "not-allowed" }}>
        <Flex flexDirection="column" className={classes.root}>
          <Flex alignItems="center" className={classes.tituloContent}>
            <Tooltip title={etapa.nome}>
              <Tipografia tipo="tituloCard" className={classes.oneLine}>
                {etapa.nome}
              </Tipografia>
            </Tooltip>
          </Flex>
          <Flex id="divisor" className={classes.divisor} />

          <Flex flexDirection="column" id={`etapa-${etapa.id}`}>
            <Paper className={classes.card}>
              <Typography color="textSecondary" style={{ padding: 8 }}>
                {intl.formatMessage({ defaultMessage: "Sem permissão para visualizar esta etapa" })}
              </Typography>
            </Paper>
          </Flex>
        </Flex>
        {etapa.gate && (
          <div className={classes.gateway}>
            <Tooltip
              title={`${intl.formatMessage({ defaultMessage: "Modo de passagem entre etapas:" })} ${etapa.gate.nome}`}
            >
              <Avatar className={classes.avatar}>{etapa.gate.sigla}</Avatar>
            </Tooltip>
            <Divider style={{ height: "100%" }} />
          </div>
        )}
      </Flex>
    </React.Fragment>
  );
}

Etapa.propTypes = {
  etapa: PropTypes.object,
  classes: PropTypes.object,
};

const enhance = compose(
  injectSheet({
    root: {
      height: "100%",
      flex: 1,
      flexBasis: "auto",

      maxWidth: 200,
    },
    gateway: {
      width: 40,
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    avatar: {
      fontSize: 14,
      width: 24,
      height: 24,
    },
    card: {
      borderRadius: 2,
      marginRight: 4,
      marginBottom: 24,
      paddingLeft: 4,
      minWidth: 96,
    },
    oneLine: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    tituloContent: {
      marginBottom: 10,
    },
    divisor: {
      marginBottom: 12,
    },
  })
);

export default enhance(Etapa);
