import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  TextField as MatTextField,
  withStyles,
  MenuItem,
  DialogActions,
  Button,
  Divider,
  Tooltip,
  Paper,
} from "@material-ui/core";
import { TextField, Select } from "formik-material-ui";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Draggable from "react-draggable";
import { defineMessage, useIntl } from "react-intl";

import { Modal, Flex, Tipografia } from "../../../../_common";
import { salvarEtapa, fecharEditar } from "../actions";
import { getFiltros } from "../api";
import SelectQuestionario from "../../../../components/SelectQuestionario";
import { TIPO_QUESTIONARIO, TIPO_GATE, TIPO_APLICACAO_QUESTIONARIO } from "../../../../_common/constantes";
import SelecaoAutomatica from "./SelecaoAutomatica";
import EditarSelecaoAutomatica from "./EditarSelecaoAutomatica";
import { useTipoPlanoSpotter } from "../../../../hooks";

const CAMPO_OBRIGATORIO = defineMessage({ defaultMessage: "Campo obrigatório *" });
const TIPOS_AVALIACAO_USUARIO = [TIPO_QUESTIONARIO.AVALIACAO_USUARIO];
const TIPOS_QUESTIONARIO_LEAD = [TIPO_QUESTIONARIO.QUESTIONARIO_LEAD];
const TIPOS_QUESTIONARIO_E_FEEDBACK = [...TIPOS_QUESTIONARIO_LEAD, TIPO_QUESTIONARIO.FEEDBACK];

const validationSchema = intl =>
  yup.object().shape({
    id: yup.number(),
    nome: yup.string().required(intl.formatMessage(CAMPO_OBRIGATORIO)),
    proximaEtapaId: yup.number().required(intl.formatMessage(CAMPO_OBRIGATORIO)),
    tipoGate: yup.number().moreThan(0, intl.formatMessage(CAMPO_OBRIGATORIO)),
    questionarioId: yup.number().when("tipoGate", {
      is: v => v === TIPO_GATE.FILTRO,
      then: yup
        .number()
        .moreThan(0, intl.formatMessage(CAMPO_OBRIGATORIO))
        .required(intl.formatMessage(CAMPO_OBRIGATORIO)),
    }),
    permissoesPerfil: yup.array(),
    tipoAplicacaoQuestionario: yup.number(),
    regras: yup.array().when("tipoAplicacaoQuestionario", {
      is: TIPO_APLICACAO_QUESTIONARIO.DINAMICO,
      then: yup
        .array()
        .of(
          yup.object().shape({
            questionarioId: yup.number().required(intl.formatMessage(CAMPO_OBRIGATORIO)),
            regraId: yup.number().required(intl.formatMessage(CAMPO_OBRIGATORIO)),
          })
        )
        .min(1),
    }),
  });

const perfis = {
  2: defineMessage({ defaultMessage: "Pre-Vendedor" }),
  6: defineMessage({ defaultMessage: "Vendedor" }),
};
const permissoes = [
  { id: 2, descricao: defineMessage({ defaultMessage: "Interagir" }) },
  { id: 1, descricao: defineMessage({ defaultMessage: "Visualizar" }) },
  { id: 0, descricao: defineMessage({ defaultMessage: "Ocultar" }) },
];
const acoes = [
  { id: 2, descricao: defineMessage({ defaultMessage: "Filtrar" }) },
  { id: 1, descricao: defineMessage({ defaultMessage: "Passar manualmente" }) },
  { id: 3, descricao: defineMessage({ defaultMessage: "Agendar" }) },
];
const initialValues = {
  nome: "",
  tipoAplicacaoQuestionario: TIPO_APLICACAO_QUESTIONARIO.PADRAO,
  ordem: 1,
  proximaEtapaId: 0,
  questionarioId: 0,
  questionarioUsuarioId: 0,
  tipoGate: 0,
  permissoes: [{ permissao: 2, tipoPerfil: 6 }, { permissao: 2, tipoPerfil: 2 }],
  regras: [{ questionarioId: "", regraId: "" }],
  possuiSelecaoGrupo: false,
  idsGrupo: [],
  permissaoGrupos: [],
};

const DraggablePaper = props => (
  <Draggable>
    <Paper {...props} />
  </Draggable>
);

function ModalEtapa({ etapas = [], etapa, open, onClose, onSubmit, classes }) {
  etapas[etapas.length - 1] = { ...etapas[etapas.length - 1], proximaEtapaId: 0 };
  const intl = useIntl();
  const [filtros, setFiltros] = useState([]);
  const isSpotterFull = useTipoPlanoSpotter();

  const getEtapaAnterior = React.useCallback(ordem => etapas[ordem - 2], [etapas]);

  const isPosAgendamento = React.useCallback(
    (tipoGate, ordem) => {
      const etapaAnterior = etapas[ordem - 2];
      if (!etapaAnterior) return false;
      const gateAnterior = etapaAnterior.tipoGate;
      return tipoGate === TIPO_GATE.FILTRO && gateAnterior === TIPO_GATE.AGENDAMENTO;
    },
    [etapas]
  );

  const isFeedback = React.useCallback(
    (tipoGate, ordem, questionarioId) => {
      const questionario = filtros.find(({ id }) => id === questionarioId) || {};
      return isPosAgendamento(tipoGate, ordem) && questionario.tipo === TIPO_QUESTIONARIO.FEEDBACK;
    },
    [etapas, isPosAgendamento, filtros]
  );

  const isQuestionarioLead = React.useCallback(
    questionarioId => {
      const selecionado = filtros.find(({ id }) => id === questionarioId);
      return selecionado?.tipo === TIPO_QUESTIONARIO.QUESTIONARIO_LEAD;
    },
    [filtros]
  );

  useEffect(
    () => {
      if (open && !filtros.length) getFiltros().then(setFiltros);
    },
    [open]
  );

  const getIdProximaEtapa = React.useCallback(
    ordem => {
      if (ordem === etapas.length + 1) return 0;
      if (etapa && etapa.ordem === ordem) return etapa.proximaEtapaId;
      return etapas.find(et => et.ordem === ordem).id;
    },
    [etapas, etapa]
  );

  const submit = React.useCallback(
    values => {
      onSubmit({ ...values, proximaEtapaId: getIdProximaEtapa(values.ordem) });
      onClose();
    },
    [onClose, getIdProximaEtapa]
  );

  // A checagem é feita aqui pois caso não seja feita, o componente pisca durante um segundo antes de ser fechado.
  if (!open) return null;

  const podeEditar = !etapa || (!etapa?.totalLeads && etapa?.tipoGate !== TIPO_GATE.VENDA);

  return (
    <Modal
      tamanho="468px"
      open={open}
      onRequestClose={onClose}
      onClose={onClose}
      title={
        etapa
          ? intl.formatMessage({ defaultMessage: "Criar etapa" })
          : intl.formatMessage({ defaultMessage: "Editar etapa" })
      }
      mostraBotaoFechar
      PaperComponent={DraggablePaper}
      maxWidth="sm"
      fullWidth
    >
      <Formik
        initialValues={{
          ...initialValues,
          // questionarioId redundante devido necessidade do endpoint salvar e para funcionamento correto da modal ao editar.
          questionarioId: etapa?.questionario?.id,
          ...etapa,
          tipoGate: etapa ? etapa.gate.tipoGate : 0,
        }}
        validationSchema={validationSchema(intl)}
        onSubmit={submit}
        onReset={() => onClose()}
      >
        {({ values, handleReset, setFieldValue }) => (
          <Form id="form-modal-etapa">
            <Field
              component={TextField}
              name="nome"
              id="nome-etapa"
              fullWidth
              label={intl.formatMessage({ defaultMessage: "Nome da etapa" })}
              placeholder={intl.formatMessage({ defaultMessage: "Entrada" })}
              className={classes.field}
              autoFocus
              required
            />

            {/*  --- EDITAR POSIÇÃO TEMPORARIAMENTE RETIRADO - BL #24929 --- */}
            {!etapa &&
              podeEditar && (
                <Tooltip
                  title={
                    etapa?.onlyAfterSales
                      ? intl.formatMessage({
                          defaultMessage:
                            "A etapa não pode ser movida, pois deve existir ao menos uma etapa após o gate de vendas",
                        })
                      : ""
                  }
                >
                  <Field
                    value={values.ordem}
                    onChange={e => {
                      setFieldValue("ordem", e.target.value);

                      const gateAnteriorOrigemAgenda =
                        getEtapaAnterior(values.ordem)?.tipoGate === TIPO_GATE.AGENDAMENTO;
                      const gateAnteriorDestinoAgenda =
                        getEtapaAnterior(e.target.value)?.tipoGate === TIPO_GATE.AGENDAMENTO;

                      if (gateAnteriorOrigemAgenda && !gateAnteriorDestinoAgenda) {
                        setFieldValue("questionarioId", 0);
                        setFieldValue("questionarioUsuarioId", 0);
                      }
                    }}
                    as={MatTextField}
                    name="ordem"
                    fullWidth
                    label={intl.formatMessage({ defaultMessage: "Posição" })}
                    id="posicao-etapa"
                    placeholder={intl.formatMessage({ defaultMessage: "Definir posição" })}
                    select
                    InputLabelProps={{ shrink: true }}
                    className={classes.field}
                    disabled={etapa?.onlyAfterSales}
                  >
                    <MenuItem key="inicio" value={1}>
                      {intl.formatMessage({ defaultMessage: "Início" })}
                    </MenuItem>
                    {etapa
                      ? etapas.map(
                          ({ id, ordem, nome }) =>
                            ordem !== etapa.ordem && (
                              <MenuItem key={id} value={ordem + 1}>
                                {`${intl.formatMessage({ defaultMessage: "Após" })} ${nome}`}
                              </MenuItem>
                            )
                        )
                      : etapas.map(({ id, nome }, index) => (
                          <MenuItem key={id} value={index + 2}>
                            {`${intl.formatMessage({ defaultMessage: "Após" })} ${nome}`}
                          </MenuItem>
                        ))}
                  </Field>
                </Tooltip>
              )}

            {/*  --- EDITAR TIPO GATE SAIDA TEMPORARIAMENTE RETIRADO - BL #24929 --- */}
            {!etapa &&
              podeEditar && (
                <Field
                  component={TextField}
                  name="tipoGate"
                  id="tipo-gate-etapa"
                  label={intl.formatMessage({ defaultMessage: "Ação de saída da etapa" })}
                  required
                  select
                  placeholder={intl.formatMessage({ defaultMessage: "Filtrar" })}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  className={classes.field}
                >
                  {acoes.map(({ id, descricao }) => (
                    <MenuItem key={id} value={id}>
                      {intl.formatMessage(descricao)}
                    </MenuItem>
                  ))}
                </Field>
              )}

            {/*  --- EDITAR FILTRO TEMPORARIAMENTE RETIRADO - BL #24929 --- */}
            {!etapa &&
              podeEditar && (
                <SelectQuestionario
                  value={values.questionarioId}
                  onChange={e => setFieldValue(e.target.name, e.target.value)}
                  tipos={
                    isPosAgendamento(values.tipoGate, values.ordem)
                      ? TIPOS_QUESTIONARIO_E_FEEDBACK
                      : TIPOS_QUESTIONARIO_LEAD
                  }
                  fullWidth
                  disabled={values.tipoGate !== 2}
                  label={
                    values.tipoAplicacaoQuestionario === TIPO_APLICACAO_QUESTIONARIO.DINAMICO
                      ? intl.formatMessage({ defaultMessage: "Filtro padrão" })
                      : intl.formatMessage({ defaultMessage: "Filtro" })
                  }
                  required
                  name="questionarioId"
                  onChangeTipo={tipo =>
                    tipo !== TIPO_QUESTIONARIO.QUESTIONARIO_LEAD &&
                    setFieldValue("tipoAplicacaoQuestionario", TIPO_APLICACAO_QUESTIONARIO.PADRAO)
                  }
                />
              )}
            {isSpotterFull && (
              <SelecaoAutomatica
                disabled={values.tipoGate !== TIPO_GATE.FILTRO || !isQuestionarioLead(values.questionarioId)}
              >
                <EditarSelecaoAutomatica
                  name="regras"
                  etapaId={etapa?.id}
                  disabled={
                    values.tipoGate !== TIPO_GATE.FILTRO ||
                    !isQuestionarioLead(values.questionarioId) ||
                    values.tipoAplicacaoQuestionario !== TIPO_APLICACAO_QUESTIONARIO.DINAMICO
                  }
                />
              </SelecaoAutomatica>
            )}

            {isSpotterFull &&
              !etapa &&
              podeEditar && (
                <Tooltip
                  title={
                    !isFeedback(values.tipoGate, values.ordem, values.questionarioId)
                      ? intl.formatMessage({
                          defaultMessage:
                            "Apenas etapas pós-agendamento com questionáriodo tipo feedback podem ter avaliação de usuário",
                        })
                      : ""
                  }
                >
                  <SelectQuestionario
                    tipos={TIPOS_AVALIACAO_USUARIO}
                    name="questionarioUsuarioId"
                    fullWidth
                    label={intl.formatMessage({ defaultMessage: "Avaliação" })}
                    disabled={!isFeedback(values.tipoGate, values.ordem, values.questionarioId)}
                  />
                </Tooltip>
              )}

            <Flex flexDirection="column" justifyContent="space-between">
              <Tipografia tipo="notaLegendas" cor="darkSecondaryText" style={{ lineHeight: "32px", minWidth: 300 }}>
                {intl.formatMessage({ defaultMessage: "Permissões" })}
              </Tipografia>

              {values.permissoes.map((perfil, index) => (
                <Flex
                  key={perfil.tipoPerfil}
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ paddingTop: 10 }}
                >
                  <Tipografia style={{ height: "100%" }}>{intl.formatMessage(perfis[perfil.tipoPerfil])}</Tipografia>

                  <Field
                    component={Select}
                    label=""
                    margin="dense"
                    name={`permissoes[${index}].permissao`}
                    MenuProps={{ style: { zIndex: 1501 } }}
                    style={{ width: "30%", minWidth: 150 }}
                    variant="filled"
                  >
                    {permissoes.map(({ id, descricao }) => (
                      <MenuItem key={id} value={id}>
                        {intl.formatMessage(descricao)}
                      </MenuItem>
                    ))}
                  </Field>
                </Flex>
              ))}
            </Flex>

            <Divider />

            <DialogActions>
              <Button onClick={() => handleReset()} id="cancelar-etapa">
                {intl.formatMessage({ defaultMessage: "Cancelar" })}
              </Button>

              <Button type="submit" color="primary" id="salvar-etapa">
                {intl.formatMessage({ defaultMessage: "Salvar" })}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

ModalEtapa.propTypes = {
  etapas: PropTypes.array,
  etapa: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  classes: PropTypes.object,
};

const enhance = compose(
  withStyles({ field: { height: 60, marginTop: 12 } }),
  connect(
    ({ pipeline }) => ({ open: pipeline.modalEditar.open, etapa: pipeline.modalEditar.etapa, etapas: pipeline.etapas }),
    { onSubmit: salvarEtapa, onClose: fecharEditar }
  )
);

export default enhance(ModalEtapa);
