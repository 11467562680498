import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { withStyles, Dialog, Avatar, Tooltip } from "@material-ui/core";
import ContentLoader from "react-content-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import { debounce } from "lodash";

import PersonCancel from "../../../../_common/icones/PersonCancel";
import { formatCurrency } from "../../../../_common/utils/string";
import withUsuario from "../../../../_common/hocs/withUsuario";
import { Tipografia, Flex, Divider, InfiniteScroll } from "../../../../_common";
// import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";
import { CardLead, SkeletonCardLead } from "../../funil/components/CardLead";
import MenuOrdenar from "./Menu";
import { buscarLeads as listar, renomearEtapa, abrirRemover, abrirEditar } from "../actions";
import CampoEditavel from "./CampoEditavel";
import permissions from "../../../../_common/permissions";
import { usePermission, useUsuario } from "../../../../hooks";
import ModalVender from "../../../lead/components/Vender/ModalVender";
import ModalCancelarReuniao from "../../../lead/components/CancelarReuniao/ModalCancelarReuniao";
import ModalDesfazerVenda from "../../../lead/components/DesfazerVenda/ModalDesfazerVenda";
import ModalEscolherQuestionario from "../../../lead/components/IniciarFiltro/ModalEscolherQuestionario";
import ModalRealizarQuestionario from "./ModalRealizarQuestionario";
import ModalRealizarAgendamento from "./ModalRealizarAgendamento";
import useEtapa from "./useEtapa";
import { LABELS_TIPO_GATE, SIGLAS_TIPO_GATE } from "../../../../_common/constantes";
import { IdsInlineManual } from "../../../../_common/utils/constants/inlineManual";

function Etapa({
  etapa,
  leads,
  buscarLeads,
  status,
  filtros,
  agendamento,
  texto,
  ordenacao,
  renomear,
  usuario,
  classes,
  funilId,
}) {
  const intl = useIntl();
  const podeVerTotal = usePermission(permissions.SOMATORIOPRODUTOS);
  const {
    dropRef,
    callback,
    leadEtapa,
    escolherQuestionario,
    vender,
    desfazerVenda,
    cancelarReuniao,
    mostrarRealizarQuestionario,
    mostrarRealizarAgendamento,
    navigateToFilter,
    setMostrarRealizarQuestionario,
    setMostrarRealizarAgendamento,
    callbackRealizarQuestionario,
    callbackRealizarAgendamento,
  } = useEtapa(etapa);

  const [anchor, setAnchor] = useState(null);
  const [ordenacaoOld, setOrdenacaoOld] = useState();
  const { id: idUsuario } = useUsuario();

  useEffect(
    () => {
      if (ordenacaoOld?.campo !== ordenacao?.campo || ordenacaoOld?.sentido !== ordenacao?.sentido) {
        setOrdenacaoOld(ordenacao);
      }
    },
    [ordenacao]
  );
  useEffect(
    () => {
      if (ordenacaoOld !== undefined) debouncedChangeHandler();
    },
    [ordenacaoOld]
  );
  useEffect(
    () => {
      debouncedChangeHandler();
    },
    [filtros, agendamento, texto, status]
  );

  const changeHandler = () => {
    buscarLeads(etapa.id, etapa.gateAnterior, true, idUsuario);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 600), []);

  const loadMore = () => {
    buscarLeads(etapa.id, etapa.gateAnterior, false, funilId);
  };

  return (
    <React.Fragment>
      <Dialog open={vender}>
        <ModalVender callback={callback} lead={leadEtapa?.lead} funilId={funilId} />
      </Dialog>

      <Dialog open={desfazerVenda}>
        <ModalDesfazerVenda callback={moved => callback(moved, etapa.id, leadEtapa.etapa.id)} id={leadEtapa?.lead.id} />
      </Dialog>

      <Dialog open={escolherQuestionario}>
        <ModalEscolherQuestionario leadId={leadEtapa?.lead.id} onClose={navigateToFilter} />
      </Dialog>

      <Dialog open={cancelarReuniao} fullWidth maxWidth="sm">
        <ModalCancelarReuniao leadId={leadEtapa?.lead.id} onClose={() => callback(false)} callback={callback} />
      </Dialog>

      <Dialog
        open={mostrarRealizarQuestionario}
        onBackdropClick={() => setMostrarRealizarQuestionario(false)}
        fullWidth
        maxWidth="md"
      >
        <ModalRealizarQuestionario callback={callbackRealizarQuestionario} item={leadEtapa} etapa={etapa} />
      </Dialog>

      <Dialog
        open={mostrarRealizarAgendamento}
        onBackdropClick={() => setMostrarRealizarAgendamento(false)}
        fullWidth
        maxWidth="md"
      >
        <ModalRealizarAgendamento callback={callbackRealizarAgendamento} item={leadEtapa} etapa={etapa} />
      </Dialog>

      <Flex style={{ width: 240 }}>
        <div
          ref={dropRef}
          id={etapa.ordem === 1 ? IdsInlineManual.FUNIL_PRIMEIRA_ETAPA : null}
          className={classes.root}
        >
          <Flex alignItems="center" style={{ marginBottom: 2 }}>
            {!usuario.isGerente ? (
              <Tooltip title={etapa.nome}>
                <Tipografia tipo="tituloCard" className={classes.oneLine}>
                  {etapa.nome}
                </Tipografia>
              </Tooltip>
            ) : (
              <Tooltip title={intl.formatMessage({ defaultMessage: "Renomear" })}>
                <CampoEditavel
                  className={classes.tituloEditavel}
                  texto={etapa.nome}
                  onSave={nome => renomear({ id: etapa.id, nome })}
                />
              </Tooltip>
            )}
            {etapa.mostrarMensagemPermissaoGrupoVizualizar && (
              <Tooltip title={intl.formatMessage({ defaultMessage: "Sem permissão para interagir nessa etapa" })}>
                <Avatar className={classes.avatarMensagem}>
                  <PersonCancel width={18} height={18} fill="#fff" />
                </Avatar>
              </Tooltip>
            )}

            <Tipografia tipo="tituloCard" cor="darkSecondaryText" className={classes.contador}>
              {!etapa.isLoading && ` (${etapa.totalLeads})`}
              {etapa.isLoading && (
                <div className={classes.containerSkeletonTotal}>
                  <ContentLoader height={20} width={30}>
                    <rect x="0" y="0" rx="3" ry="3" width="30" height="20" />
                  </ContentLoader>
                </div>
              )}
            </Tipografia>
            {!etapa.isLoading && (
              <Tipografia cor="darkSecondaryText">
                <ArrowDropDown onClick={e => setAnchor(e.currentTarget)} style={{ cursor: "pointer" }} />
              </Tipografia>
            )}
          </Flex>
          <Flex alignItems="center" style={{ marginBottom: 16 }}>
            {podeVerTotal && (
              <React.Fragment>
                {!etapa.isLoading ? (
                  <span className={classes.totalValue}>{formatCurrency(etapa.valorTotal)}</span>
                ) : (
                  <span className={classes.totalValue}>{intl.formatMessage({ defaultMessage: "R$" })} --</span>
                )}
              </React.Fragment>
            )}
          </Flex>
          <AutoSizer disableWidth>
            {({ height }) => (
              <Flex
                flexDirection="column"
                style={{
                  height: height - 44,
                }}
                className={classes.container}
                id={`etapa-${etapa.id}`}
              >
                <InfiniteScroll
                  initialLoad={false}
                  loadMore={loadMore}
                  hasMore={etapa.hasMore && !etapa.isLoading}
                  loader={null}
                  useWindow={false}
                >
                  {Boolean(leads) && leads.map(lead => <CardLead key={lead.id} lead={lead} faseLead={etapa} />)}

                  {etapa.isLoading &&
                    Array.from({ length: Math.floor(Math.random() * 4 + 3) }).map((item, i) => (
                      <SkeletonCardLead key={i} />
                    ))}
                </InfiniteScroll>
              </Flex>
            )}
          </AutoSizer>
        </div>
        {etapa.gate &&
          !etapa.ultima && (
            <div className={classes.gateway}>
              <Tooltip
                title={intl.formatMessage(
                  { defaultMessage: "Modo de passagem entre etapas: {etapa}" },
                  { etapa: intl.formatMessage(LABELS_TIPO_GATE[etapa.gate.tipoGate]) }
                )}
              >
                <Avatar className={classes.avatar}>{intl.formatMessage(SIGLAS_TIPO_GATE[etapa.gate.tipoGate])}</Avatar>
              </Tooltip>
              <Divider style={{ height: "100%" }} />
            </div>
          )}
      </Flex>
      <MenuOrdenar anchor={anchor} onClose={() => setAnchor(null)} etapa={etapa} />
    </React.Fragment>
  );
}

Etapa.propTypes = {
  etapa: PropTypes.object,
  leads: PropTypes.array,
  buscarLeads: PropTypes.func,
  filtros: PropTypes.object,
  agendamento: PropTypes.object,
  status: PropTypes.array,
  texto: PropTypes.string,
  ordenacao: PropTypes.object,
  renomear: PropTypes.func,
  usuario: PropTypes.object,
  classes: PropTypes.object,
  funilId: PropTypes.number,
};

const enhance = compose(
  withStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      flexBasis: "auto",
      minWidth: 200,
      maxWidth: 200,
    },
    tituloEditavel: {
      fontSize: 20,
      fontWeight: 300,
      padding: [0, 5],
      borderRadius: 5,
      marginTop: 2,
      border: "1px solid transparent",
      "&:hover": { borderColor: "lightgray" },
      "&:focus-within": { boxShadow: "0 0 0 2px #457AB7" },
    },
    containerSkeletonTotal: { width: 30, height: 20 },
    contador: {
      marginLeft: 4,
      flexGrow: 1,
    },
    avatar: {
      fontSize: 14,
      width: 24,
      height: 24,
    },
    avatarMensagem: {
      width: 24,
      height: 24,
      backgroundColor: "#f5845e",
      color: "#fff",
      marginLeft: 4,
    },
    gateway: {
      width: 40,
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    oneLine: {
      width: 200,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    totalValue: {
      width: 200,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      paddingLeft: 6,
      color: "rgba(0, 0, 0, 0.54)",
    },
    container: {
      paddingBottom: 24,
      overflow: "hidden scroll",
    },
    undroppable: {
      backgroundColor: "#E0E0E0",
    },
  }),
  connect(
    ({ baseLeads: { campoBusca, listagens, filtrosPorEtapa }, pipeline }, { etapa: { id } }) => ({
      agendamento: filtrosPorEtapa[id],
      texto: campoBusca,
      ordenacao: pipeline.ordenacao[id],
      status: listagens.statusAtividade,
      leads: pipeline.leads[id],
    }),
    { renomear: renomearEtapa, buscarLeads: listar, excluir: abrirRemover, editar: abrirEditar }
  ),
  withUsuario
);

export default enhance(Etapa);
