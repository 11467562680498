import actions from "./actions";
import { createReducer } from "../../../_common/utils/redux";

const TIPO_GATE_VENDA = 4;
const initialState = {
  etapas: [],
  leads: {}, // [etapaId]: leads[]
  funil: { nome: "", id: 1, default: true }, // nome: string, id: number, default: boolean
  ordenacao: {}, // campo: string, sentido: string
  paginacao: {}, // [etapaId]: pagina
  dialogRemover: { open: false, etapa: null },
  dialogMover: { open: false, origem: null, anterior: null, posterior: null, lead: null },
  modalEditar: { open: false, etapa: null },
  modalTransferir: { open: false, ids: [] },
};
const verificarEdicao = (state, etapas, etapa, index) => {
  if (etapa.tipoGate === TIPO_GATE_VENDA) {
    return false;
  }
  return state.etapas.length === etapas.length ? state.etapas[index].podeEditar : !etapa.totalLeads;
};

export default createReducer(initialState, {
  [actions.ABRIR_MOVER]: (state, { origem, anterior, posterior, lead }) => ({
    ...state,
    dialogMover: { open: true, origem, anterior, posterior, lead },
  }),
  [actions.FECHAR_MOVER]: state => ({
    ...state,
    dialogMover: { ...initialState.dialogMover },
  }),
  [actions.ABRIR_REMOVER]: (state, { etapa }) => ({ ...state, dialogRemover: { open: true, etapa } }),
  [actions.FECHAR_REMOVER]: state => ({
    ...state,
    dialogRemover: { open: false, etapa: null },
  }),
  [actions.ABRIR_EDITAR]: (state, { etapa }) => ({ ...state, modalEditar: { open: true, etapa } }),
  [actions.FECHAR_EDITAR]: state => ({
    ...state,
    modalEditar: { open: false, etapa: null },
  }),
  // [actions.BUSCAR_FUNIS]: (state, { funis }) => ({ ...state, funis }),
  [actions.BUSCAR_ETAPAS]: (state, { etapas, funil }) => ({
    ...state,
    etapas: etapas.map((etapa, index) => ({
      ...etapa,
      pagina: 0,
      leads: [],
      podeEditar: verificarEdicao(state, etapas, etapa, index),
    })),
    funil,
  }),
  [actions.BUSCAR_LEADS]: (state, { leads, valor, id, count, zerar, pagina }) => ({
    ...state,
    etapas: state.etapas.map(
      etapa =>
        // TODO corrigir verificação de "hasMore"
        etapa.id === id
          ? { ...etapa, totalLeads: count, valorTotal: valor, isLoading: false, hasMore: leads.length === 20 }
          : etapa
    ),
    leads: { ...state.leads, [id]: zerar ? leads : [...state.leads[id], ...leads] },
    paginacao: { ...state.paginacao, [id]: pagina },
  }),
  [actions.RENOMEAR_FUNIL]: (state, { id, nome }) => ({
    ...state,
    funis: state.funis.map(funil => (funil.id === id ? { ...funil, nome } : funil)),
  }),
  [actions.RENOMEAR_ETAPA]: (state, { id, nome }) => ({
    ...state,
    etapas: state.etapas.map(etapa => (etapa.id === id ? { ...etapa, nome } : etapa)),
  }),
  [actions.ZERAR_PAGINACAO]: (state, { id }) => ({
    ...state,
    etapas: state.etapas.map(etapa => (etapa.id === id ? { ...etapa, hasMore: true } : etapa)),
    paginacao: { ...state.paginacao, [id]: 0 },
    leads: { ...state.leads, [id]: [] },
  }),
  [actions.INICIAR_CARREGAMENTO]: (state, { id }) => ({
    ...state,
    etapas: state.etapas.map(etapa => (etapa.id === id ? { ...etapa, isLoading: true } : etapa)),
  }),
  [actions.MUDAR_ORDENACAO]: (state, { id, campo, sentido }) => ({
    ...state,
    ordenacao: { ...state.ordenacao, [id]: { campo, sentido } },
  }),
  [actions.ZERAR_ETAPAS]: state => ({
    ...state,
    etapas: [],
  }),
  [actions.ABRIR_TRANSFERIR]: (state, { ids }) => ({ ...state, modalTransferir: { open: true, ids } }),
  [actions.FECHAR_TRANSFERIR]: state => ({ ...state, modalTransferir: { open: false, ids: [] } }),
});
