import axios from "axios";
import { mutate } from "swr";
import { defineMessage } from "react-intl";

import { getLeads, getEtapas, moverEtapa as mover, ordenar, remover, salvar, moverLead } from "./api";
import { createAction } from "../../../_common/utils/redux";
import { filtrarEtapa } from "../../../store/reducers/baseLeads/baseLeadsActions";
import createSnackBar from "../../../_common/utils/snackbar/createSnackbar";
import createSnackbarAPIException from "../../../_common/utils/snackbar/createSnackbarAPIException";
import { TIPO_APLICACAO_QUESTIONARIO } from "../../../_common/constantes";
import { AjaxBlackout } from "../../../_common";

const ACTIONS = {
  BUSCAR_FUNIS: "PIPELINE/BUSCAR_FUNIS",
  BUSCAR_ETAPAS: "PIPELINE/BUSCAR_ETAPAS",
  BUSCAR_LEADS: "PIPELINE/BUSCAR_LEADS",
  RENOMEAR_FUNIL: "PIPELINE/RENOMEAR_FUNIL",
  RENOMEAR_ETAPA: "PIPELINE/RENOMEAR_ETAPA",
  ZERAR_PAGINACAO: "PIPELINE/ZERAR_PAGINACAO",
  MUDAR_ORDENACAO: "PIPELINE/MUDAR_ORDENACAO",
  INICIAR_CARREGAMENTO: "PIPELINE/INICIAR_CARREGAMENTO",
  ZERAR_ETAPAS: "PIPELINE/ZERAR_ETAPAS",
  ABRIR_REMOVER: "PIPELINE/ABRIR_REMOVER",
  FECHAR_REMOVER: "PIPELINE/FECHAR_REMOVER",
  FECHAR_EDITAR: "PIPELINE/FECHAR_EDITAR",
  ABRIR_EDITAR: "PIPELINE/ABRIR_EDITAR",
  FECHAR_MOVER: "PIPELINE/FECHAR_MOVER",
  ABRIR_MOVER: "PIPELINE/ABRIR_MOVER",
  ABRIR_TRANSFERIR: "PIPELINE/ABRIR_TRANSFERIR",
  FECHAR_TRANSFERIR: "PIPELINE/FECHAR_TRANSFERIR",
};

// TODO implementa tratamento de múltiplos funis
// export const buscarFunis = () => createAction(ACTIONS.BUSCAR_FUNIS);

const agruparEtapasOcultas = etapas => {
  const ocultasAgrupadas = etapas.reduce((all, etapaAtual) => {
    const etapaAnterior = all && all[all.length - 1];

    if (!etapaAnterior || !etapaAnterior.oculta || !etapaAtual.oculta) return [...all, etapaAtual];

    const ultimaEtapDoArrayAll = all.pop();
    return [
      ...all,
      {
        ...ultimaEtapDoArrayAll,
        proximaEtapaId: etapaAtual.proximaEtapaId,
        gate: etapaAtual.gate,
        ultimaEtapaOcultaId: etapaAtual.id,
      },
    ];
  }, []);
  return ocultasAgrupadas;
};

export const buscarEtapas = funilId => async dispatch => {
  const { etapas, ...funil } = await getEtapas(funilId);
  const ocultasAgrupadas = agruparEtapasOcultas(etapas);
  ocultasAgrupadas[ocultasAgrupadas.length - 1].ultima = true;

  ocultasAgrupadas
    .map(({ id, ordenacao = {} }) => ({ id, campo: ordenacao.campo, sentido: ordenacao.sentido }))
    .forEach(({ id, campo, sentido }) => {
      dispatch(createAction(ACTIONS.MUDAR_ORDENACAO, { id, campo, sentido }));
      dispatch(filtrarEtapa(id, { tipoAgendamento: undefined }));
    });

  dispatch(
    createAction(ACTIONS.BUSCAR_ETAPAS, {
      etapas: ocultasAgrupadas.map((etp, index) => ({
        ...etp,
        hasMore: true,
        isLoading: false,
        onlyAfterSales: index + 1 === etapas.length && etapas[index - 1]?.gate.tipoGate === 4,
      })),
      funil,
    })
  );
};

export const buscarLeads = (id, gate, zerar, idUsuario) => async (dispatch, getState) => {
  dispatch(createAction(ACTIONS.INICIAR_CARREGAMENTO, { id }));
  if (zerar) dispatch(createAction(ACTIONS.ZERAR_PAGINACAO, { id }));

  const { baseLeads, pipeline } = getState();

  let { filtros } = baseLeads.modals.buscaAvancada;
  filtros = filtros || {};
  const {
    listagens: { statusAtividade },
  } = baseLeads;

  const statusFilters = statusAtividade.filter(status => status.checked);
  if (statusFilters.length !== statusAtividade) filtros.statusAtividades = statusFilters.map(status => status.id);

  const pagina = pipeline.paginacao[id];

  const { count, leads, valor } = await getLeads(id, idUsuario, {
    gateAnterior: gate,
    pagina,
    ordenacao: pipeline.ordenacao[id],
    filtros: {
      ...filtros,
      EnderecoParcial: filtros.enderecoContem,
      texto: baseLeads.campoBusca,
      tipoAgendamento: baseLeads.filtrosPorEtapa[id].tipoAgendamento,
      listagens: undefined,
      todosEstados: undefined,
      todasCidades: undefined,
    },
  });

  const etapa = getState().pipeline.etapas.find(e => e.id === id);
  const leadsComEtapa = leads.map(l => ({ situacao: 1, ...l, etapa }));

  dispatch(
    createAction(ACTIONS.BUSCAR_LEADS, {
      id,
      leads: leadsComEtapa,
      valor,
      count,
      zerar,
      pagina: pagina + 1,
    })
  );
};

export const moverEtapa = ({ id, proximaEtapa, novaProximaEtapa, nome, questionarioId }) => async (
  dispatch,
  getState
) => {
  const {
    funil: { id: funilId },
  } = getState().pipeline;
  try {
    await mover({
      id,
      antiga: proximaEtapa,
      nova: novaProximaEtapa,
      funilId,
      questionarioId,
      nome,
    });
    dispatch(createAction(ACTIONS.ZERAR_ETAPAS));
    createSnackBar(defineMessage({ defaultMessage: "Etapa movida com sucesso" }));
    dispatch(buscarEtapas());
  } catch (err) {
    createSnackbarAPIException(err);
  }
};

export const mudarOrdenacao = ({ campo, id }) => async (dispatch, getState) => {
  const { ordenacao } = getState().pipeline;
  const sentido = ordenacao[id].campo === campo && ordenacao[id].sentido === "Asc" ? "Desc" : "Asc";
  dispatch(createAction(ACTIONS.MUDAR_ORDENACAO, { id, campo, sentido }));
  ordenar(id, campo, sentido);
};

export const renomearEtapa = ({ id, nome }) => async dispatch => {
  await axios.post("/api/pipeline/etapa/renomear", { id, nome });
  dispatch(createAction(ACTIONS.RENOMEAR_ETAPA, { id, nome }));
};

export const renomearFunil = ({ id, nome }) => async dispatch => {
  await axios.post("/api/pipeline/funil/renomear", { id, nome });
  dispatch(createAction(ACTIONS.RENOMEAR_FUNIL, nome));
};

export const copiarEtapa = ({ id, nome, questionarioId }) => async dispatch => {
  try {
    AjaxBlackout.Show();
    await axios.post("/api/pipeline/etapa/copiar", { id, nome, questionarioId });
    dispatch(createAction(ACTIONS.ZERAR_ETAPAS));
    createSnackBar(defineMessage({ defaultMessage: "Etapa copiada com sucesso" }));
    dispatch(buscarEtapas());
  } catch (err) {
    createSnackbarAPIException(err);
  } finally {
    AjaxBlackout.Hide();
  }
};

export const salvarEtapa = ({ id, regras = [], ...etapa }) => async (dispatch, getState) => {
  try {
    const {
      funil: { id: funilId },
    } = getState().pipeline;
    const { data: novoId } = await salvar({ id, funilId, ...etapa });

    const regrasASalvar = regras.filter(r => r.questionarioId && r.regraId).map(r => ({ ...r, etapaId: novoId }));
    if (etapa.tipoAplicacaoQuestionario === TIPO_APLICACAO_QUESTIONARIO.DINAMICO && regrasASalvar.length) {
      await axios.post(
        `/api/pipeline/SegmentacaoRegraEtapaQuestionario/salvar?etapaId=${novoId}`,
        regras.filter(r => r.questionarioId && r.regraId).map(r => ({ ...r, etapaId: novoId }))
      );
      mutate(`/api/pipeline/SegmentacaoRegraEtapaQuestionario/Buscar?etapaId=${novoId}`, regras, false);
    } else {
      await axios.post(`/api/pipeline/SegmentacaoRegraEtapaQuestionario/salvar?etapaId=${novoId}`, []);
      mutate(`/api/pipeline/SegmentacaoRegraEtapaQuestionario/Buscar?etapaId=${novoId}`, [], false);
    }

    mutate("/api/pipeline/etapa/listar?funilid=null");
    dispatch(buscarEtapas());
  } catch (err) {
    createSnackbarAPIException(err);
  }
};

export const abrirEditar = ({ etapa }) => createAction(ACTIONS.ABRIR_EDITAR, { etapa });
export const fecharEditar = () => createAction(ACTIONS.FECHAR_EDITAR);

export const abrirRemover = ({ etapa }) => createAction(ACTIONS.ABRIR_REMOVER, { etapa });
export const fecharRemover = ({ excluiu, alias }) => async (dispatch, getState) => {
  try {
    if (excluiu) {
      const {
        dialogRemover: { etapa },
        funil: { id: funilId },
      } = getState().pipeline;
      await remover({
        id: etapa.id,
        proximaEtapaId: etapa.proximaEtapaId,
        funilId,
        alias,
        questionarioId: etapa.questionario.id,
        nome: etapa.nome,
      });
      await axios.post(`/api/pipeline/SegmentacaoRegraEtapaQuestionario/excluir?etapaId=${etapa.id}`);
      dispatch(buscarEtapas());
    }
  } catch (err) {
    createSnackbarAPIException(err);
  } finally {
    dispatch(createAction(ACTIONS.FECHAR_REMOVER));
  }
};

export const abrirMover = (lead, etapa) => (dispatch, getState) => {
  const { etapas } = getState().pipeline;
  const anterior = etapas.find(
    ({ proximaEtapaId, gate: { tipoGate } }) => tipoGate === 1 && proximaEtapaId === etapa.id
  );
  if (anterior && anterior.ultimaEtapaOcultaId) {
    anterior.id = anterior.ultimaEtapaOcultaId;
  }
  const posterior = etapa.gate.tipoGate === 1 ? etapas.find(({ id }) => id === etapa.proximaEtapaId) : undefined;
  dispatch(createAction(ACTIONS.ABRIR_MOVER, { lead, anterior, posterior, origem: etapa }));
};
export const fecharMover = ({ lead, origem, destino }) => async dispatch => {
  if (lead && origem && destino) {
    await moverLead(lead, destino);
    [origem, destino].forEach(etapa => dispatch(buscarLeads(etapa.id, etapa.gateAnterior, true, etapa.funilId)));
  }
  dispatch(createAction(ACTIONS.FECHAR_MOVER));
};

// Esta ação não está sendo mais utilizada. Usar recarregarEtapaAnterior.
export const recarregarAgendamento = etapaId => (dispatch, getState) => {
  const {
    pipeline: { etapas },
  } = getState();
  const [etapaAntes, etapaDepois] = etapas.filter(
    ({ id, proximaEtapaId }) => id === etapaId || proximaEtapaId === etapaId
  );
  dispatch(buscarLeads(etapaAntes.id, etapaAntes.gateAnterior, true, etapaAntes.funilId));
  dispatch(buscarLeads(etapaDepois.id, etapaDepois.gateAnterior, true, etapaDepois.funilId));
};

export const recarregarEtapaAnterior = etapaId => (dispatch, getState) => {
  const {
    pipeline: { etapas },
  } = getState();
  const etapaAnterior = etapas.find(etapa => etapa.id === etapaId);

  if (etapaAnterior) dispatch(buscarLeads(etapaAnterior.id, etapaAnterior.gateAnterior, true, etapaAnterior.funilId));
};

export const recarregarEntrada = () => (dispatch, getState) => {
  const {
    pipeline: { etapas },
  } = getState();
  dispatch(recarregarEtapa(etapas[0].id));
};

export const recarregarEtapa = etapaId => (dispatch, getState) => {
  const {
    pipeline: { etapas },
  } = getState();
  const etapa = etapas.find(({ id }) => id === etapaId);
  if (etapa) dispatch(buscarLeads(etapa.id, etapa.gateAnterior, true, etapa.funilId));
};

export const recarregarVenda = () => (dispatch, getState) => {
  const {
    pipeline: { etapas },
  } = getState();
  const etapa = etapas.find(({ gateAnterior }) => gateAnterior === 4);
  if (etapa) dispatch(buscarLeads(etapa.id, etapa.gateAnterior, true, etapa.funilId));
};

export const abrirTransferir = ids => createAction(ACTIONS.ABRIR_TRANSFERIR, { ids });
export const fecharTransferir = () => createAction(ACTIONS.FECHAR_TRANSFERIR);

export default ACTIONS;
