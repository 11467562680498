import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  FormControlLabel,
  Checkbox,
  withStyles,
} from "@material-ui/core";

import { mudarOrdenacao } from "../../actions";
import { Tipografia } from "../../../../../_common";
import { TIPO_AGENDAMENTO, TIPO_GATE } from "../../../../../_common/constantes";
import { toggleAgendamento as toggle } from "../../../../../store/reducers/baseLeads/baseLeadsActions";
import { useTipoPlanoSpotter } from "../../../../../hooks";

function MenuOrdenar({ anchor, etapa, ordenacao, tipoAgendamento, toggleAgendamento, mudarOrdem, onClose, classes }) {
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();
  // eslint-disable-next-line
  const ItemOrdem = ({ children, name, ...props }) => {
    let icon;
    if (ordenacao.campo === name) icon = ordenacao.sentido === "Asc" ? "down" : "up";

    return (
      <MenuItem
        name={name}
        onClick={() =>
          mudarOrdem({
            campo: name,
            id: etapa.id,
          })
        }
        {...props}
      >
        <ListItemText className={classes.text}>
          <Tipografia cor="darkPrimaryText">{children}</Tipografia>
        </ListItemText>
        {icon && (
          <ListItemIcon className={classes.arrow}>{icon === "up" ? <ArrowUpward /> : <ArrowDownward />}</ListItemIcon>
        )}
      </MenuItem>
    );
  };
  return (
    <Menu
      open={!!anchor}
      onClose={() => {
        onClose();
      }}
      anchorEl={anchor}
      PaperProps={{ style: { width: 240 } }}
    >
      <ItemOrdem name="CreatedAt">{intl.formatMessage({ defaultMessage: "Data de cadastro" })}</ItemOrdem>
      <ItemOrdem name="UpdatedAt">{intl.formatMessage({ defaultMessage: "Data de atualização" })}</ItemOrdem>
      <ItemOrdem name="Lead">{intl.formatMessage({ defaultMessage: "Ordem alfabética" })}</ItemOrdem>
      <ItemOrdem name="Conversao">{intl.formatMessage({ defaultMessage: "Data de entrada na etapa" })}</ItemOrdem>
      {isSpotterFull && (
        <ItemOrdem name="Valor">{intl.formatMessage({ defaultMessage: "Valor da negociação" })}</ItemOrdem>
      )}
      {etapa.gateAnterior === TIPO_GATE.FILTRO && [
        <ItemOrdem key="GateQualificacao" name="Qualificacao">
          {intl.formatMessage({ defaultMessage: "Qualificação" })}
        </ItemOrdem>,
        <ItemOrdem key="GateQuantidadeLigacoes" name="QuantidadeLigacoes">
          {intl.formatMessage({ defaultMessage: "Tentativas de ligação" })}
        </ItemOrdem>,
      ]}
      {etapa.gateAnterior === TIPO_GATE.VENDA && [
        <ItemOrdem key="GateDataVenda" name="DataVenda">
          {intl.formatMessage({ defaultMessage: "Data de venda" })}
        </ItemOrdem>,
        <ItemOrdem key="GateDataReuniao" name="DataReuniao">
          {intl.formatMessage({ defaultMessage: "Data de reunião" })}
        </ItemOrdem>,
      ]}
      {etapa.gateAnterior === TIPO_GATE.AGENDAMENTO && [
        <ItemOrdem key="GateReuniaoConfirmacao" name="ReuniaoConfirmacao">
          {intl.formatMessage({ defaultMessage: "Confirmação de reunião" })}
        </ItemOrdem>,
      ]}
      {etapa.gateAnterior === TIPO_GATE.AGENDAMENTO && [
        <ItemOrdem key="GateDataReuniao" name="DataReuniao">
          {intl.formatMessage({ defaultMessage: "Data da reunião" })}
        </ItemOrdem>,
      ]}
      <Divider />
      <MenuItem disabled>
        <Tipografia cor="darkPrimaryText">{intl.formatMessage({ defaultMessage: "Mostrar leads para" })}</Tipografia>
      </MenuItem>
      {etapa.gate.tipoGate === TIPO_GATE.AGENDAMENTO &&
        [
          {
            label: intl.formatMessage({ defaultMessage: "Agendamento" }),
            value: TIPO_AGENDAMENTO.REAGENDAMENTO,
          },
          {
            label: intl.formatMessage({ defaultMessage: "Reagendamento" }),
            value: TIPO_AGENDAMENTO.AGENDAMENTO,
          },
        ].map(({ label, value }) => (
          <MenuItem key={label}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!tipoAgendamento || tipoAgendamento !== value}
                  onChange={() => toggleAgendamento(etapa.id, value)}
                  value={label}
                  color="primary"
                  disabled={tipoAgendamento && tipoAgendamento !== value}
                />
              }
              label={label}
            />
          </MenuItem>
        ))}
    </Menu>
  );
}

MenuOrdenar.propTypes = {
  anchor: PropTypes.object,
  onClose: PropTypes.func,
  etapa: PropTypes.object,
  ordenacao: PropTypes.object,
  tipoAgendamento: PropTypes.object,
  toggleAgendamento: PropTypes.func,
  mudarOrdem: PropTypes.func,
};

const mapState = ({ pipeline, baseLeads }, { etapa }) => ({
  ordenacao: pipeline.ordenacao[etapa.id],
  tipoAgendamento: baseLeads.filtrosPorEtapa[etapa.id].tipoAgendamento,
});

const mapDispatch = {
  mudarOrdem: mudarOrdenacao,
  toggleAgendamento: toggle,
};

const enhance = compose(
  connect(
    mapState,
    mapDispatch
  ),
  withStyles({ arrow: { marginRight: 0 }, text: { paddingBottom: 8, paddingTop: 8 } })
);

export default enhance(MenuOrdenar);
