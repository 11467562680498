import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { useIntl } from "react-intl";

function ModalRealizarQuestionario({ item, etapa, callback }) {
  const intl = useIntl();

  return (
    <>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Como deseja pular a etapa?" })}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage(
            { defaultMessage: "O lead será movido da etapa {etapaOrigem} para a etapa {etapaDestino}." },
            {
              etapaOrigem: item.etapa.nome,
              etapaDestino: etapa.nome,
            }
          )}
        </DialogContentText>
        <DialogContentText>
          {intl.formatMessage(
            {
              defaultMessage:
                "Ao movê-lo sem responder ao questionário, as transições e métricas entre as etapas não serão registradas. Caso queira responder ao questionário posteriormente, será preciso retornar o lead para a etapa {etapaOrigem} e refazer a ação.",
            },
            {
              etapaOrigem: item.etapa.nome,
            }
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => callback(false, item)}>
          {intl.formatMessage({ defaultMessage: "Ignorar questionário" })}
        </Button>
        <Button color="primary" onClick={() => callback(true, item)}>
          {intl.formatMessage({ defaultMessage: "Responder ao questionário" })}
        </Button>
      </DialogActions>
    </>
  );
}

ModalRealizarQuestionario.propTypes = {
  item: PropTypes.object,
  etapa: PropTypes.object,
  callback: PropTypes.func,
};

export default connect()(ModalRealizarQuestionario);
