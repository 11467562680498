import useSWR from "swr";
import { getCachedFetcher } from "../../../../_common/DataFetchingConfig/DataFetchingConfig";

function usePermissaoEtapa(etapa) {
  if (!etapa?.funilId && !etapa?.funil?.id) return null;

  const { data: permissoes, isLoading } = useSWR(
    `/api/pipeline/PermissaoPerfilEtapa/Buscar?funilId=${etapa?.funilId ?? etapa?.funil.id}`,
    {
      fetcher: getCachedFetcher({ maxAge: 60 * 60 }),
    }
  );

  if (isLoading || !permissoes) return null;

  return permissoes.find(permissao => permissao.etapaId === etapa?.id)?.permissao;
}

export default usePermissaoEtapa;
